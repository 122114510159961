import React from 'react';
import Button from '@atlaskit/button';
import { useInlineField } from '@atlassian/jira-forge-controllers-inline-fields-state';
import { FAILED_FIELD_STATE } from '@atlassian/jira-forge-controllers-inline-fields-state/src/constants.tsx';

type Props = {
	fieldId: string;
};

export const ForgeFieldDispatchButton = (props: Props) => {
	const { fieldId } = props;
	const [fieldState, { dispatchRetryFunction }] = useInlineField(fieldId);

	const onClickHandler = async () => {
		if (fieldId !== undefined) {
			await dispatchRetryFunction(fieldId);
		}
	};

	const renderRetryButton = () => {
		if (fieldState !== undefined && fieldState?.status === FAILED_FIELD_STATE) {
			return (
				<Button spacing="none" appearance="link" onClick={onClickHandler}>
					Try again?
				</Button>
			);
		}

		return null;
	};

	return renderRetryButton();
};
