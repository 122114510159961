import React from 'react';
import TextAreaField from '@atlassian/jira-issue-create-commons/src/ui/textarea-field/index.tsx';
import { TEXT_AREA_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DescriptionFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { textAreaFieldWriter } from '../writers';

const TextAreaCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<TextAreaField {...props} />
);

export const TextAreaCustomFieldTypeConfig: FieldTypeConfig<string> = {
	type: TEXT_AREA_CF_TYPE,
	Component: TextAreaCustomFieldTypeComponent,
	placeholder: <DescriptionFieldPlaceholder />,
	writer: textAreaFieldWriter,
};
