import React, { useMemo, type ReactNode } from 'react';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension';
import { EditAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/custom-field';
import type { ForgeUiAnalyticsAttributes } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type {
	CustomField,
	CustomFieldType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import {
	toIssueTypeId,
	toProjectId,
	toProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { getExtensionIdFromLegacyFieldType, isForgeLegacyFieldType } from '../utils';
import { EditMode } from './edit';
import { FieldContainer } from './FieldContainer';
import { ForgeFieldMessage } from './forge-field-message';
import {
	type FieldProps,
	type IssueOrRequestType,
	type SelectedProject,
	REQUEST_TYPE,
} from './types';
import { transformValueToExtensionData } from './utils';

export type Props = {
	fieldType: string;
	fieldId: string;
	fieldName: string;
	fieldProps: FieldProps<ForgeCustomFieldValue>;
	error?: string;
	selectedProject: SelectedProject | undefined;
	selectedIssueType: IssueOrRequestType | undefined;
	extension: CustomField | CustomFieldType | undefined | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	renderDefaultComponent: (fieldProps: FieldProps<any>, error?: string) => ReactNode;
};

export type ForgeFieldProps = Props & {
	renderFieldMessage: (error?: string) => ReactNode;
};

export const ForgeFieldView = ({
	fieldType,
	fieldName,
	fieldId,
	fieldProps,
	error,
	selectedProject,
	selectedIssueType,
	extension,
	renderDefaultComponent,
}: Props) => {
	const { onChange, value } = fieldProps;

	const extensionPayload = useMemo(() => {
		if (value === undefined) {
			return {
				fieldValue: null,
			};
		}
		return { fieldValue: transformValueToExtensionData(value, extension) };
	}, [extension, value]);

	const extensionData = useMemo(() => {
		const defaultExtensionData = {
			fieldId,
			fieldType: isForgeLegacyFieldType(fieldType)
				? getExtensionIdFromLegacyFieldType(fieldType) ?? fieldType
				: fieldType,
			fieldName,
			renderContext: 'issue-create',
		};

		if (!selectedProject || !selectedIssueType) {
			return defaultExtensionData;
		}

		const issueTypeId = toIssueTypeId(
			selectedIssueType.type === REQUEST_TYPE
				? selectedIssueType.issueTypeId
				: selectedIssueType.value,
		);

		return {
			...defaultExtensionData,
			project: {
				id: toProjectId(selectedProject.value),
				key: toProjectKey(selectedProject.projectKey),
				type: selectedProject.projectType,
			},
			issue: {
				type:
					(selectedIssueType.type === REQUEST_TYPE && selectedIssueType.issueTypeName) ||
					selectedIssueType.label,
				typeId: issueTypeId,
			},
		};
	}, [selectedProject, selectedIssueType, fieldId, fieldName, fieldType]);

	const analyticsAttributes = useMemo(
		() => ({
			renderContext: 'issue-create',
			fieldType: extension?.properties.type,
			module: extension?.type,
			...(extension ? getAnalyticsAttributesFromExtension(extension) : {}),
		}),
		[extension],
	);

	if (!extension) {
		return null;
	}

	const renderDefaultView = () => (
		<>
			{renderDefaultComponent(fieldProps, error)}
			<ForgeScreenEvent
				attributes={{
					...analyticsAttributes,
					renderMode: 'default',
				}}
			/>
		</>
	);

	if (!extension.properties.edit) {
		return renderDefaultView();
	}

	return (
		<EditMode
			extension={extension}
			extensionData={extensionData}
			extensionPayload={extensionPayload}
			analyticsAttributes={analyticsAttributes}
			onChange={onChange}
		/>
	);
};

export const ForgeField = (props: ForgeFieldProps) => {
	const { renderFieldMessage, ...rest } = props;
	const { fieldId, extension, error } = rest;
	const analyticsAttributes = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({
				renderContext: 'issue-create',

				...(extension && getAnalyticsAttributesFromExtension(extension)),
			}) as ForgeUiAnalyticsAttributes,
		[extension],
	);

	return (
		<EditAnalyticsWrapper attributes={analyticsAttributes}>
			<FieldContainer width="100%" paddingBottom={6}>
				<ForgeFieldView {...rest} />
				<ForgeFieldMessage
					error={error}
					fieldId={fieldId}
					renderFieldMessage={renderFieldMessage}
				/>
			</FieldContainer>
		</EditAnalyticsWrapper>
	);
};
