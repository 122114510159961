import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	noMatches: {
		id: 'issue-field-affected-services.edit.no-matches',
		defaultMessage: 'No Matches',
		description: 'Select menu message. Shows there is no affected services found',
	},
	failedFetch: {
		id: 'issue-field-affected-services.edit.failed-fetch',
		defaultMessage: 'Search failed. Please try again.',
		description: 'Message when fetching affected services fails',
	},
});

export default messages;
