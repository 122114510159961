import React from 'react';
import RadioSelectField from '@atlassian/jira-issue-create-commons/src/ui/radio-select-field/index.tsx';
import type { Option } from '@atlassian/jira-issue-field-radio-select/src/ui/edit/types.tsx';
import { RADIO_BUTTONS_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { radioSelectFieldWriter } from '../writers';

const RadioButtonsCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<RadioSelectField {...props} />
);

export const RadioButtonsCustomFieldTypeConfig: FieldTypeConfig<Option> = {
	type: RADIO_BUTTONS_CF_TYPE,
	Component: RadioButtonsCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: radioSelectFieldWriter,
};
