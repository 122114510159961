import React, { useEffect } from 'react';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { asyncCascadingSelectEditEntryPoint } from './ui/cascading-select-view/entrypoint';
import type { Props } from './ui/cascading-select-view/types';

const EMPTY_OBJECT = {} as const;

export const AsyncCascadingSelectEdit = (props: Props) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncCascadingSelectEditEntryPoint,
		// use stable object reference to avoid re-renders
		EMPTY_OBJECT,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="async-jira-issue-field-cascading-select-edit"
			packageName="jira-issue-field-cascading-select"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
			fallback={<SkeletonCompiled width="350px" height="40px" />}
			errorFallback="unmount"
		/>
	);
};
