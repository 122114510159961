import { useRef, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type {
	FetchServicesResponse,
	AffectedServiceOption,
} from '@atlassian/jira-issue-field-affected-services/src/common/types.tsx';
import { mapServiceEntitiesToOptions } from '@atlassian/jira-issue-field-affected-services/src/ui/edit/utils.tsx';
import type { JiraAffectedService, JiraAffectedServiceFieldInput, FieldValue } from './types';

export const mutateAffectedServicesField = (
	selectedAffectedServices: FieldValue,
	fieldId: string,
): JiraAffectedServiceFieldInput => ({
	affectedServicesField: {
		fieldId,
		affectedServices: selectedAffectedServices.map(({ value }) => ({ serviceId: value })),
	},
});

export const transformDefaultAffectedServices = (
	defaultAffectedServices: JiraAffectedService[],
): FieldValue =>
	defaultAffectedServices.map((service) => ({
		value: service.serviceId,
	}));

export const useFetchAffectedServicesName = () => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const baseUrl = '';

	const handleFetch = useRef(
		(ids: string[], setSelectedOptions: (arg1: AffectedServiceOption[]) => void) => {
			setLoading(true);
			fetchJson(`${baseUrl}/rest/service-registry-api/service`, {
				method: 'POST',
				body: JSON.stringify({
					ids,
				}),
			})
				.then((response: FetchServicesResponse) => {
					setLoading(false);
					setSelectedOptions(mapServiceEntitiesToOptions(response.services));
				})
				.catch((error) => {
					setLoading(false);
					setSelectedOptions([]);
					fireErrorAnalytics({
						meta: {
							id: 'useFetchAffectedServicesName',
							packageName: 'jiraIssueCreateCommons',
							teamName: 'gryffindor',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				});
		},
	);
	return [{ isLoading }, handleFetch.current] as const;
};
