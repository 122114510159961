import React from 'react';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { TEAMS_PLATFORM_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import { TeamField } from '../../../ui/fields/team-field';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { singleUserPickerFieldWriter } from '../writers';

const TeamsPlatformCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<TeamField {...props} defaultValue={undefined} />
);

export const TeamsPlatformCustomFieldTypeConfig: FieldTypeConfig<TeamValue> = {
	type: TEAMS_PLATFORM_CF_TYPE,
	Component: TeamsPlatformCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: singleUserPickerFieldWriter,
};
