import type {
	LabelsValue,
	LabelOption as Option,
} from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import type {
	Label,
	LabelsSuggestionList,
} from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';

export const mapDataToLabelOptions = (data: LabelsValue): Option[] => {
	if (!data) {
		return [];
	}

	return data.map((value) => ({
		value,
		label: value,
	}));
};

export const mapLabelOptionsToData = (options: Option[]): Label[] =>
	options.map(({ value }) => value);

export const mapLabelsResponseToLabelOptions = (response: LabelsSuggestionList): Option[] => {
	const { suggestions } = response;

	if (!suggestions) {
		return [];
	}

	return suggestions.map(({ label }) => ({
		label,
		value: label,
	}));
};
