import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import SprintEdit from '@atlassian/jira-issue-field-sprint/src/ui/edit/index.tsx';
import { ACTIVE_SPRINT_STATE } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import { FieldMessage } from '../../common/ui/field-message';
import { closeMenuOnScroll } from '../../common/utils/select';
import messages from './messages';
import type { Props, FieldValue, SprintFieldConfig } from './types';
import { transformDefaultSprintValue, getSprintSuggestions } from './utils';

const SprintField = (props: Props) => {
	const { formatMessage } = useIntl();
	const {
		width,
		autoCompleteUrl = '/rest/greenhopper/1.0/sprint/picker?query=',
		fieldProps: { value, onChange, id, isRequired },
		selectedProject,
		error,
		fieldId,
		autoFocus = false,
		description,
		fieldName,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isEditable = true,
		nonEditableReason,
		isMini,
	} = props;
	const renderSprintEdit = () => (
		<SprintEdit
			fieldId={fieldId}
			autoCompleteUrl={autoCompleteUrl}
			onChange={onChange}
			value={value}
			isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
			fetchSuggestionsOnMount={false}
			autoFocus={autoFocus}
			isInvalid={Boolean(error)}
			onCloseMenuOnScroll={onCloseMenuOnScroll}
			isDisabled={isEditable === false}
			getSuggestions={getSprintSuggestions}
			{...(selectedProject?.projectKey
				? {
						projectKey: selectedProject?.projectKey,
					}
				: {})}
			inputId={id}
			isRequired={isRequired}
		/>
	);
	return (
		<FieldContainer width={width}>
			{isEditable === false ? (
				<Tooltip content={nonEditableReason?.message || ''}>{renderSprintEdit()}</Tooltip>
			) : (
				renderSprintEdit()
			)}
			<FieldMessage description={description} error={error} fieldName={fieldName} isMini={isMini} />
			{}
			{/* @ts-expect-error - TS2339 - Property 'state' does not exist on type 'FieldValue'. */}
			{value && value.state === ACTIVE_SPRINT_STATE ? (
				<Box xcss={messageContainerStyles}>
					<SectionMessage
						appearance="warning"
						testId="issue-create-commons.ui.sprint-field.required-fields-message"
					>
						{formatMessage(messages.activeSprintSelectionWarningMessage)}
					</SectionMessage>
				</Box>
			) : null}
		</FieldContainer>
	);
};

export default withFormField({
	transformDefaultValue: transformDefaultSprintValue,
	shouldRenderFieldMessage: false,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<SprintFieldConfig, FieldValue>, any>'.
})<SprintFieldConfig, FieldValue>(SprintField);
export { mutateJiraSprintField } from './utils';

const messageContainerStyles = xcss({
	marginTop: 'space.050',
});
