import React from 'react';
import SingleSelectField from '@atlassian/jira-issue-create-commons/src/ui/single-select-field/index.tsx';
import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import { CATEGORY_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { selectFieldWriter } from '../writers';

const CategoryFieldTypeComponent = (props: ProvidedFieldProps) => (
	<SingleSelectField {...props} defaultValue={undefined} />
);

export const CategoryFieldTypeConfig: FieldTypeConfig<SelectOption> = {
	type: CATEGORY_TYPE,
	Component: CategoryFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: selectFieldWriter,
};
