import flatMap from 'lodash/flatMap';
import { memoizedHashString } from '@atlassian/jira-issue-analytics/src/utils/hash-attribute/index.tsx';
import type { Option, Options } from '@atlassian/jira-issue-field-select-base/src/common/types.tsx';

export const getSafeValue = (val: string | number): string =>
	memoizedHashString(val.toString()).toString();

export const getSafeOptionsForAnalytics = (
	items: ReadonlyArray<Readonly<Option>> | Option,
): string[] => {
	const itemsToFilter = Array.isArray(items) ? items : [items];
	return itemsToFilter.filter((item) => item.value).map(({ value }) => getSafeValue(value));
};

export const flattenOptions = (optionGroups: Options | never[]): Option[] =>
	flatMap(
		optionGroups.map((optionOrGroup) =>
			optionOrGroup.options ? optionOrGroup.options : [optionOrGroup],
		),
	);

export const getOptionValuesLengths = (
	items: ReadonlyArray<Readonly<Option>> | Option,
): number[] => {
	const itemsToFilter = Array.isArray(items) ? items : [items];
	return itemsToFilter
		.filter((item) => item.value)
		.map(({ value }) => (value != null ? `${value}`.length : 0));
};

export const filterOptions = (options: Option[], query?: string): Option[] =>
	options.filter(
		(option) =>
			(typeof option.value === 'string' &&
				option.value.toLowerCase().includes((query || '').toLowerCase())) ||
			(typeof option.label === 'string' &&
				option.label.toLowerCase().includes((query || '').toLowerCase())),
	);
