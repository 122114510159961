import type { Group, Option } from '@atlassian/jira-issue-field-select-base/src/common/types.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ClosedSprint } from '@atlassian/jira-shared-types/src/rest/jira/closed-sprint.tsx';
import {
	ACTIVE_SPRINT_STATE,
	FUTURE_SPRINT_STATE,
	SPRINT_STATES,
	type SprintPayload,
	type SprintState,
	type SprintSuggestion,
} from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import messages from './messages';
import type { SprintValue, SprintFieldValue } from './types';

type SprintOption = {
	state?: SprintState;
	data?: {
		boardId?: number;
		date?: string;
		fromCache?: boolean;
	};
} & Option;

export const getRapidViewUrl = (sprintId: number) =>
	`/secure/GHGoToBoard.jspa?sprintId=${sprintId}`;

export const hasState =
	(state: string) =>
	(sprint: SprintValue): boolean =>
		sprint.state !== undefined && sprint.state.toLowerCase() === state.toLowerCase();

export const findSprintWithState = (sprints: SprintFieldValue | null, state: string) => {
	if (!sprints) {
		return undefined;
	}

	return sprints.find(hasState(state));
};

export const toStateKey = (state?: string): SprintState | undefined => {
	if (state !== undefined) {
		const stateKey = state.toUpperCase();
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly ACTIVE: "ACTIVE"; readonly CLOSED: "CLOSED"; readonly FUTURE: "FUTURE"; }'.
		return SPRINT_STATES[stateKey];
	}

	return undefined;
};

// Used externally by some packages
export const mapSprintOptionToData = ({ value }: SprintOption): number => Number(value);

export const mapOptionToSprintValue = (option: SprintOption): SprintValue => ({
	id: Number(option.value),
	name: String(option.label),
	state: option.state,
	// mapSprintValueToOption ensures there will always be a boardId
	boardId: Number(option.data?.boardId) || 0,
});

export const mapOptionsToSprintFieldValue = (items: SprintOption[]): SprintFieldValue =>
	items.map(mapOptionToSprintValue);

export const mapOptionToSprintSuggestion = (option: SprintOption): SprintSuggestion => ({
	id: Number(option.value),
	boardName: option.description,
	name: String(option.label),
	// mapSprintSuggestionToOption ensures there will always be a state
	// @ts-expect-error - TS2322 - Type 'SprintState | undefined' is not assignable to type 'SprintState'.
	stateKey: toStateKey(option.state),
	// mapSprintSuggestionToOption ensures there will always be a date
	date: String(option.data?.date),
});

export const mapOptionsToSprintSuggestions = (items: SprintOption[]): SprintSuggestion[] =>
	items.map(mapOptionToSprintSuggestion);

export const mapSprintSuggestionToOption = (sprint: SprintSuggestion): SprintOption => ({
	id: sprint.id,
	description: sprint.boardName,
	label: sprint.name,
	state: sprint.stateKey,
	value: sprint.id,
	data: {
		date: sprint.date,
		fromCache: sprint.fromCache,
	},
});

export const mapSprintSuggestionsToOptions = (items: SprintSuggestion[]): SprintOption[] =>
	items.map(mapSprintSuggestionToOption);

export const mapSprintValueToClosedSprint = (sprint: SprintValue): ClosedSprint => ({
	id: sprint.id,
	name: sprint.name,
	href: getRapidViewUrl(sprint.id),
	endDate: sprint.endDate || '',
});

export const mapSprintValueToOption = (sprint: SprintValue): SprintOption => ({
	label: sprint.name,
	value: sprint.id,
	href: getRapidViewUrl(sprint.id),
	state: toStateKey(sprint.state),
	data: {
		boardId: sprint.boardId,
		date: sprint.startDate,
	},
});

export const mapSprintFieldValueToOptions = (items: SprintFieldValue): SprintOption[] =>
	items.map(mapSprintValueToOption);

export const transformSuggestions = (
	suggestionsFromServer: SprintPayload,
	formatMessage: FormatMessage,
): Group[] => {
	const serverSuggestions = suggestionsFromServer.suggestions || [];
	const allMatches = suggestionsFromServer.allMatches || [];
	const allItems = serverSuggestions.concat(allMatches);

	const activeItems: SprintOption[] = allItems
		.filter((sprint) => sprint.stateKey === ACTIVE_SPRINT_STATE)
		.map(mapSprintSuggestionToOption);

	const futureItems: SprintOption[] = allItems
		.filter((sprint) => sprint.stateKey === FUTURE_SPRINT_STATE)
		.map(mapSprintSuggestionToOption);

	const sprints: Group[] = [
		{
			label: formatMessage(messages.activeSprints),
			options: activeItems,
		},
		{
			label: formatMessage(messages.futureSprints),
			options: futureItems,
		},
	];
	return sprints;
};
