import React from 'react';
import type { UserOption } from '@atlassian/jira-base-user-picker/src/types.tsx';
import SingleUserPickerField from '@atlassian/jira-issue-create-commons/src/ui/single-userpicker-field/index.tsx';
import { USER_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { AssigneeFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig } from '../types';
import { singleUserPickerFieldWriter } from '../writers';

export const UserCustomFieldTypeConfig: FieldTypeConfig<UserOption> = {
	type: USER_CF_TYPE,
	Component: SingleUserPickerField,
	placeholder: <AssigneeFieldPlaceholder />,
	writer: singleUserPickerFieldWriter,
};
