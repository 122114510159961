import React, { useState } from 'react';
import { Dropzone, Browser } from '@atlaskit/media-picker';
import { Clipboard } from '@atlassian/jira-clipboard/src/ui/index.tsx';
import type { AttachmentsUploadProps } from './types';

const AttachmentsUpload = (props: AttachmentsUploadProps) => {
	const [isBrowserOpen, setIsBrowserOpen] = useState<boolean>(false);
	const {
		dropzoneContainer,
		onUploadStart,
		onUploadError,
		mediaClientConfig,
		uploadConfig,
		onUploadEnd,
		children: renderPropFn,
	} = props;

	const dropzoneConfig = {
		...uploadConfig,
	};

	const browserConfig = {
		...uploadConfig,
		multiple: true,
	};

	const clipboardConfig = {
		...uploadConfig,
	};

	if (dropzoneContainer) {
		dropzoneConfig.container = dropzoneContainer;
	}

	return (
		<>
			<Browser
				isOpen={isBrowserOpen}
				config={browserConfig}
				mediaClientConfig={mediaClientConfig}
				onUploadsStart={onUploadStart}
				onError={onUploadError}
				onEnd={onUploadEnd}
				onClose={() => setIsBrowserOpen(false)}
			/>
			<Dropzone
				mediaClientConfig={mediaClientConfig}
				config={dropzoneConfig}
				onUploadsStart={onUploadStart}
				onEnd={onUploadEnd}
			/>
			<Clipboard
				mediaClientConfig={mediaClientConfig}
				config={clipboardConfig}
				onUploadsStart={onUploadStart}
				onEnd={onUploadEnd}
				isolated={false}
			/>
			{renderPropFn({ setIsBrowserOpen })}
		</>
	);
};

export default AttachmentsUpload;
