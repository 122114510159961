import React from 'react';
import MultiGroupPickerField from '@atlassian/jira-issue-create-commons/src/ui/multi-grouppicker-field/index.tsx';
import type { UserPickerOption } from '@atlassian/jira-issue-field-group-picker/src/common/types.tsx';
import { MULTI_GROUP_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { multipleGroupPickerFieldWriter } from '../writers';

const MultiGroupCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<MultiGroupPickerField {...props} />
);

export const MultiGroupCustomFieldTypeConfig: FieldTypeConfig<UserPickerOption[]> = {
	type: MULTI_GROUP_CF_TYPE,
	Component: MultiGroupCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: multipleGroupPickerFieldWriter,
};
