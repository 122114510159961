import React from 'react';
import { AffectedServicesField } from '@atlassian/jira-issue-create-commons';
import type { AffectedServiceOption } from '@atlassian/jira-issue-field-affected-services/src/common/types.tsx';
import { SERVICE_ENTITY_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { multiSelectFieldWriter } from '../writers';

const ServiceEntityCustomFieldComponent = (props: ProvidedFieldProps) => {
	// strip off any query strings from URL as the field component also adds them in
	const autoCompleteUrl = props.autoCompleteUrl?.split('?')[0];

	return <AffectedServicesField {...props} autoCompleteUrl={autoCompleteUrl} />;
};

export const ServiceEntityCustomFieldTypeConfig: FieldTypeConfig<AffectedServiceOption[]> = {
	type: SERVICE_ENTITY_CF_TYPE,
	Component: ServiceEntityCustomFieldComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: multiSelectFieldWriter,
};
