import queryString from 'query-string';

// TODO: Move src/packages/issue/issue-view/src/common/service/issue/issue-view-mode.js to a platform package once we consume the extracted sprint field in issue view - https://jdog.jira-dev.com/browse/BENTO-9971

type ViewMode = 'planning' | 'planning.nodetail' | 'detail' | '';

const getViewMode = (): ViewMode => queryString.parse(window.location.search).view || '';

// Includes check is useful here in case LegacyJS loads first and/or Sidebar closed
export const isBacklogSidebar = (): boolean => getViewMode().includes('planning');

// Helper functions to get the checkbox value in sprint edit component from local storage
export const EDIT_SPRINT_CHECKBOX_CACHE_KEY = 'edit_sprint_cache_value';

export const getCheckboxValueFromLocalStorage = (key: string): boolean => {
	const value = window.localStorage?.getItem(key);

	return value ? JSON.parse(value) : true;
};

export const setCheckBoxValueInLocalStorage = (key: string, value: boolean) => {
	try {
		// @ts-expect-error - TS2345 - Argument of type 'boolean' is not assignable to parameter of type 'string'.
		window.localStorage.setItem(key, value);
	} catch {
		// do nothing
	}
};
