import React from 'react';
import DateTimeField from '@atlassian/jira-issue-create-commons/src/ui/date-time-field/index.tsx';
import { DATETIME_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DateTimeFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { dateTimeFieldWriter } from '../writers';

const DateTimeCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<DateTimeField {...props} />
);

export const DateTimeCustomFieldTypeConfig: FieldTypeConfig<string> = {
	type: DATETIME_CF_TYPE,
	Component: DateTimeCustomFieldTypeComponent,
	placeholder: <DateTimeFieldPlaceholder />,
	writer: dateTimeFieldWriter,
};
