import React from 'react';
import Select from '@atlaskit/select';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { Props } from './types';

const SelectEdit = (props: Props) => {
	const {
		classNamePrefix,
		placeholder = '',
		options,
		onChange,
		onBlur,
		onFocus,
		autoFocus = false,
		isInvalid,
		isMulti = false,
		isDisabled = false,
		value,
		isClearable,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		onFilterOption,
		inputId,
		isRequired,
		ariaLabel,
		ariaLabelledBy,
	} = props;

	return (
		<Select
			placeholder={placeholder}
			options={options}
			value={value}
			classNamePrefix={classNamePrefix}
			// @ts-expect-error - TS2322 - Type '(option: SelectOption) => void' is not assignable to type '(value: SelectOption | OptionsType<SelectOption> | null, action: ActionMeta<SelectOption>) => void'.
			onChange={onChange}
			onBlur={onBlur}
			isMulti={isMulti}
			onFocus={onFocus}
			autoFocus={autoFocus}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			isClearable={isClearable}
			isDisabled={isDisabled}
			closeMenuOnScroll={onCloseMenuOnScroll}
			filterOption={onFilterOption}
			inputId={inputId}
			required={isRequired}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledBy}
		/>
	);
};

export default SelectEdit;
