import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export type GlobalComponentsProperties = {
	key: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
};

const GLOBAL_COMPONENTS_PROPERTY_KEY = 'jira.global.components';

// Default projectKey regex, source: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-api/src/main/java/com/atlassian/jira/util/JiraKeyUtils.java#421
const PROJECT_KEY_REGEX = /^[A-Z][A-Z]+$/;

const url = (projectIdOrKey: string, entityKey: string) =>
	`/rest/api/3/project/${projectIdOrKey}/properties/${entityKey}`;

const getProjectId = async (projectKey: string): Promise<string | undefined> => {
	const project = await performGetRequest(`/rest/api/3/project/${projectKey}`);
	return project?.id;
};

export const getGlobalComponentsProperty = async (
	projectKey: string,
): Promise<GlobalComponentsProperties> => {
	try {
		let endpoint;
		if (!PROJECT_KEY_REGEX.test(projectKey)) {
			// We need to fetch the project id here because some users have malformed project keys that are not compatible with the project properties endpoint
			const projectId = await getProjectId(projectKey);
			if (!projectId) {
				return { key: GLOBAL_COMPONENTS_PROPERTY_KEY, value: null };
			}
			endpoint = url(projectId, GLOBAL_COMPONENTS_PROPERTY_KEY);
		} else {
			endpoint = url(projectKey, GLOBAL_COMPONENTS_PROPERTY_KEY);
		}
		return await performGetRequest(endpoint);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// The endpoint returns 404 if the project property is not set, but we don't treat it as an
		// actual error
		if (error.statusCode === 404) {
			return { key: GLOBAL_COMPONENTS_PROPERTY_KEY, value: null };
		}

		fireErrorAnalytics({
			meta: {
				id: 'getGlobalComponentsProperty',
				packageName: 'jiraCompassCommon',
				teamName: 'compass-lodestone',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
			attributes: { projectKey },
		});

		throw error;
	}
};

export async function setGlobalComponentsProperty(projectKey: string, value: boolean) {
	try {
		let endpoint;
		if (!PROJECT_KEY_REGEX.test(projectKey)) {
			// We need to fetch the project id here because some users have malformed project keys that are not compatible with the project properties endpoint
			const projectId = await getProjectId(projectKey);
			if (!projectId) {
				return;
			}
			endpoint = url(projectId, GLOBAL_COMPONENTS_PROPERTY_KEY);
		} else {
			endpoint = url(projectKey, GLOBAL_COMPONENTS_PROPERTY_KEY);
		}
		await performPutRequest(endpoint, { body: JSON.stringify(value) });
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'setGlobalComponentsProperty',
				packageName: 'jiraCompassCommon',
				teamName: 'compass-lodestone',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
			attributes: { projectKey },
		});

		throw error;
	}
}
