import React, { useCallback, useMemo, useRef, useState, useEffect, type FocusEvent } from 'react';
import debounce from 'lodash/debounce';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Option, Options } from '@atlassian/jira-issue-field-select-base/src/common/types.tsx';
import { FailedFetchOptionWrapper } from '@atlassian/jira-issue-field-select-base/src/ui/failed-fetch-options/index.tsx';
import { filterOptionByLabelAndFilterValues } from '@atlassian/jira-issue-field-select-base/src/ui/filter-options-by-label-and-filter-values/index.tsx';
import { formatOptionLabel } from '@atlassian/jira-issue-field-select-base/src/ui/format-option-label/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import SelectWithAnalytics from '@atlassian/jira-issue-select-with-analytics';
import { SPRINT_TYPE } from '@atlassian/jira-platform-field-config';
import type { SprintPayload } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import { transformSuggestions as defaultTransformSuggestions } from '../../common/utils';
import { useSprintSuggestions } from '../../services/suggestions/main.tsx';
import {
	setCheckBoxValueInLocalStorage,
	getCheckboxValueFromLocalStorage,
	EDIT_SPRINT_CHECKBOX_CACHE_KEY,
} from '../utils';
import Menu from './menu';
import messages from './messages';
import type { Props } from './types';

export const FETCH_DEBOUNCE = 300;

const SprintEdit = (props: Props) => {
	const {
		autoCompleteUrl,
		projectKey,
		autoFocus,
		onChange,
		onFocus,
		onBlur,
		spacing,
		value,
		options,
		noOptionsMessage,
		loadingMessage,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		useTransformSuggestions,
		onPostTransformSuggestions,
		getSuggestions,
		fetchSuggestionsOnMount = true,
		fieldId = SPRINT_TYPE,
		isInvalid = false,
		isDisabled = false,
		inputId,
		isRequired,
		openMenuOnFocus,
		'aria-labelledby': ariaLabelledBy,
	} = props;

	const [suggestions, setSuggestions] = useState<Options>();
	const [defaultSuggestions, setDefaultSuggestions] = useState<Options>();
	const [hasLastFetchFailed, setHasLastFetchFailed] = useState<boolean>(false);
	const lastQuery = useRef<string>('');
	const [restrictSprints, setRestrictSprint] = useState<boolean>(
		getCheckboxValueFromLocalStorage(EDIT_SPRINT_CHECKBOX_CACHE_KEY),
	);
	const isInitialMount = useRef<boolean>(true);

	const { formatMessage } = useIntl();
	const [{ isLoadingSuggestions }, { getSprintSuggestions, setIsLoadingSuggestions }] =
		useSprintSuggestions({ getSuggestions });

	const onCheckboxClick = useCallback(() => {
		setRestrictSprint(!restrictSprints);
		setCheckBoxValueInLocalStorage(EDIT_SPRINT_CHECKBOX_CACHE_KEY, !restrictSprints);
	}, [restrictSprints]);

	const componentProps = useMemo(
		() => ({ isChecked: restrictSprints, onChange: onCheckboxClick, projectKey }),
		[restrictSprints, projectKey, onCheckboxClick],
	);

	const handleSuggestions = useCallback(
		(results: SprintPayload, query: string) => {
			if (lastQuery.current === query) {
				const transformSuggestions = useTransformSuggestions || defaultTransformSuggestions;
				const response = transformSuggestions(results, formatMessage);

				if (onPostTransformSuggestions) {
					onPostTransformSuggestions(response, query);
				}

				setSuggestions(response);

				if (query === '') {
					setDefaultSuggestions(response);
				}
			}
		},
		[formatMessage, useTransformSuggestions, onPostTransformSuggestions],
	);

	const getFailedFetchMessage = useCallback(
		() => (
			<FailedFetchOptionWrapper>{formatMessage(messages.failedFetch)}</FailedFetchOptionWrapper>
		),
		[formatMessage],
	);

	const fetchSuggestions = useCallback(
		async (query = ''): Promise<void> => {
			try {
				let response;
				if (restrictSprints && projectKey) {
					response = await getSprintSuggestions(autoCompleteUrl, query, projectKey);
				} else {
					response = await getSprintSuggestions(autoCompleteUrl, query);
				}
				handleSuggestions(response, query);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setHasLastFetchFailed(true);
				const failedOption: Option[] = [
					{
						label: getFailedFetchMessage(),
						value: '',
						isDisabled: true,
					},
				];
				setSuggestions(failedOption);
			}
		},
		[
			autoCompleteUrl,
			restrictSprints,
			projectKey,
			getFailedFetchMessage,
			getSprintSuggestions,
			handleSuggestions,
		],
	);

	useMemo(() => {
		if (fetchSuggestionsOnMount) {
			if (!options && !defaultSuggestions) {
				setIsLoadingSuggestions(true);
				fetchSuggestions();
			} else if (options && !defaultSuggestions) {
				setDefaultSuggestions(options);
				setSuggestions(options);
			} else {
				lastQuery.current = '';
				setIsLoadingSuggestions(true);
				fetchSuggestions();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, FETCH_DEBOUNCE), [
		restrictSprints,
	]);

	const getNoOptionsMessage = useCallback(
		(): string =>
			noOptionsMessage !== undefined ? noOptionsMessage : formatMessage(messages.empty),
		[formatMessage, noOptionsMessage],
	);
	const getLoadingMessage = useCallback(
		(): string => (loadingMessage !== undefined ? loadingMessage : formatMessage(messages.loading)),
		[formatMessage, loadingMessage],
	);

	const filterOption = useCallback(
		(option: Option, query: string): boolean => {
			if (hasLastFetchFailed) {
				// If the fetch has failed, we will show a disabled option to indicate the error to users
				return true;
			}
			return filterOptionByLabelAndFilterValues<Option>(option, query || '');
		},
		[hasLastFetchFailed],
	);

	const onQueryChange = useCallback(
		(query: string): void => {
			debouncedFetchSuggestions.cancel();
			setHasLastFetchFailed(false);
			if (query) {
				lastQuery.current = query;
				setIsLoadingSuggestions(true);
				debouncedFetchSuggestions(query);
			} else {
				lastQuery.current = '';
				setIsLoadingSuggestions(false);
				setSuggestions(defaultSuggestions);
			}
		},
		[debouncedFetchSuggestions, defaultSuggestions, setIsLoadingSuggestions, setSuggestions],
	);

	const onSelectFocus = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			if (!suggestions) {
				setIsLoadingSuggestions(true);
				fetchSuggestions('');
			}
			onFocus && onFocus(e);
		},
		[fetchSuggestions, onFocus, setIsLoadingSuggestions, suggestions],
	);

	const onSelectBlur = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			onBlur && onBlur(e);
		},
		[onBlur],
	);

	const onValueChange = useCallback(
		(newValues: Option[]): void => {
			// We're passing in suggestions for validation
			// e.g., if selected sprint is active and will affect the issue
			onChange && onChange(newValues, suggestions);
		},
		[onChange, suggestions],
	);

	const menuPosition = isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined;

	// Fetch the suggestions again if user toggles the checkbox
	useEffect(() => {
		if (!isInitialMount.current) {
			setIsLoadingSuggestions(true);
			debouncedFetchSuggestions(lastQuery.current);
		}
		isInitialMount.current = false;
	}, [restrictSprints, debouncedFetchSuggestions, setIsLoadingSuggestions]);

	return (
		<SelectWithAnalytics
			inputId={inputId}
			isRequired={isRequired}
			isClearable
			hideSelectedOptions
			autoFocus={autoFocus}
			fieldId={fieldId}
			options={suggestions}
			value={value}
			onBlur={onSelectBlur}
			menuPosition={menuPosition}
			componentsProps={projectKey ? componentProps : undefined}
			components={projectKey ? { Menu } : undefined}
			styles={defaultSelectStyles}
			onFocus={onSelectFocus}
			noOptionsMessage={getNoOptionsMessage}
			closeMenuOnScroll={onCloseMenuOnScroll}
			loadingMessage={getLoadingMessage}
			placeholder={formatMessage(messages.placeholder)}
			onInputChange={onQueryChange}
			onChange={onValueChange}
			spacing={spacing}
			isLoading={isLoadingSuggestions}
			filterOption={filterOption}
			formatOptionLabel={formatOptionLabel}
			validationState={isInvalid === true ? 'error' : null}
			isDisabled={isDisabled}
			openMenuOnFocus={openMenuOnFocus}
			{...(fg('bulk-transitions-fix-a11y-violations') && {
				'aria-labelledby': ariaLabelledBy,
			})}
		/>
	);
};

export default SprintEdit;
