import type {
	FieldId,
	FieldInternalShape,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/types';
import {
	createActionsHook,
	createHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import {
	initializeField,
	processChange,
	setFieldValue,
	updateFields,
	successFieldsInitalisation,
	startFieldsInitialisationTimeout,
	setError,
	resetError,
	registerFields,
	unregisterFields,
	setAppErrors,
	resetAppErrors,
	initializeNoScreenTab,
	initializeScreenTabs,
	updateScreenTabs,
	updateActiveScreenTab,
} from './actions';
import { initializeFields } from './actions/initialize-field';
import type { AnalyticsData, IssueAdjustmentsState } from './types';

const name = 'issue-adjustments';
export const initialState: IssueAdjustmentsState = {
	formData: null,
	screenTabs: null,
	appliedChanges: null,
	error: null,
	analyticsData: {},
	registeredFieldsDeprecated: {},
	registeredFields: {},
	triggerFieldInitializationKey: 0,
	// Selector checks if this is not equal 0 in the useFieldReady
	numberOfSupportedFields: 0,
	internalFormMetadata: {},
	viewType: null,
	experienceDataIsLoading: false,
	lastProcessedChange: null,
	fieldsInitialization: {
		startTime: null,
		timeoutId: undefined,
		status: 'notStarted',
	},
	appsErrors: {},
};

// triggerFieldInitializationKey, viewType and numberOfSupportedFields should not be reset on project or issue type change
const {
	triggerFieldInitializationKey,
	viewType: scopedViewType,
	numberOfSupportedFields,
	...stateForResetAllFields
} = initialState;

export const actions = {
	triggerFieldInitialization:
		() =>
		({ getState, setState }: StoreActionApi<IssueAdjustmentsState>) => {
			setState({
				triggerFieldInitializationKey: getState().triggerFieldInitializationKey + 1,
			});
		},
	processChange,
	setFieldValue,
	initializeField,
	initializeFields,
	startFieldsInitialisationTimeout,
	successFieldsInitalisation,
	registerFields,
	unregisterFields,
	updateFields,
	clearAppliedValue:
		(fieldId: FieldId) =>
		({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
			const previousState = getState().appliedChanges || {};

			if (previousState[fieldId]) {
				const { value, ...otherFieldChanges } = previousState[fieldId];

				setState({
					appliedChanges: {
						...previousState,
						[fieldId]: otherFieldChanges,
					},
				});
			}
		},
	resetAllFields:
		() =>
		({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
			// Clear the fieldsInitialization timeout to avoid potetianl side effects.
			clearTimeout(getState().fieldsInitialization.timeoutId);

			setState(stateForResetAllFields);
		},
	setError,
	resetError,
	setAnalyticsData:
		(analyticsData: Partial<AnalyticsData>) =>
		({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
			const previousState: Partial<AnalyticsData> = getState().analyticsData ?? {};

			setState({
				analyticsData: {
					...previousState,
					...analyticsData,
				},
			});
		},
	setNumberOfSupportedFields:
		(numberOfAllSupportedFields: number) =>
		({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
			setState({ numberOfSupportedFields: numberOfAllSupportedFields });
		},
	/**
	 * This is used to block onInit for specific case like Issue View background refresh loading.
	 */
	setExperienceDataIsLoading:
		(experienceDataIsLoading: boolean) =>
		({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
			setState({ experienceDataIsLoading });
		},
	setAppErrors,
	resetAppErrors,
	initializeNoScreenTab,
	initializeScreenTabs,
	updateScreenTabs,
	updateActiveScreenTab,
};

export type Actions = typeof actions;
// It was required to satisfiy TS with the async action
export type HookActions = ReturnType<typeof useIssueAdjustmentsActions>;

export const store = createStore<IssueAdjustmentsState, Actions>({
	name,
	initialState,
	actions,
});

export const useIssueAdjustments = createHook(store);

const stableEmptyFieldFromIframe: FieldInternalShape = {};
export const useAdjustedField = createHook(store, {
	selector: (state: IssueAdjustmentsState, fieldId: FieldId) =>
		state.appliedChanges?.[fieldId] || stableEmptyFieldFromIframe,
});

export const useAnalyticsData = createHook(store, {
	selector: (state: IssueAdjustmentsState) => state.analyticsData,
});

export const useIssueAdjustmentsActions = createActionsHook(store);

export const useAdjustedScreenTabs = createHook(store, {
	selector: (state: IssueAdjustmentsState) => state.screenTabs,
});
