import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	activeSprints: {
		id: 'issue-field-sprint.common.active-sprints',
		defaultMessage: 'Active',
		description: '',
	},
	futureSprints: {
		id: 'issue-field-sprint.common.future-sprints',
		defaultMessage: 'Future',
		description: '',
	},
	recentSprints: {
		id: 'issue-field-sprint.common.recent-sprints',
		defaultMessage: 'Recent sprints',
		description: 'Header displayed in labels list used to mark recently selected items',
	},
});
