import React, { useCallback, useRef, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import UserPicker, { type OnChange } from '@atlaskit/user-picker';
import type { UserPickerControlStyleConfig } from '@atlassian/jira-base-user-picker/src/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { UserPickerOption, Props } from '../../common/types';
import { transformGroupToUserOption } from '../../common/utils';
import useGroupsQuery from '../../services/group-query';
import messages from './messages';
import { invalidStateStyle } from './styled';

const DEFAULT_SEARCH_DEBOUCE_TIMEOUT = 300;

const GroupPicker = ({
	autoCompleteUrl,
	value,
	onFocus,
	onBlur,
	onChange,
	isMulti,
	isDisabled,
	fieldId,
	width,
	// @ts-expect-error - TS2339 - Property 'searchDebounceTimeout' does not exist on type 'Props'.
	searchDebounceTimeout = DEFAULT_SEARCH_DEBOUCE_TIMEOUT,
	isInvalid,
	autoFocus = false,
	placeholder,
	onCloseMenuOnScroll,
	menuPosition = undefined,
	groupPickerStyles = {},
	isClearable = true,
	...fieldProps
}: Props) => {
	const { formatMessage } = useIntl();
	const previousQuery = useRef<string>('');
	const { groups, loading, fetchGroups } = useGroupsQuery(autoCompleteUrl || '');
	const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

	const getUserPickerControlStyle = useCallback<UserPickerControlStyleConfig>(
		(styles) => {
			if (isInvalid === true) {
				return {
					...styles,
					...invalidStateStyle,
				};
			}
			return styles;
		},
		[isInvalid],
	);

	const handleSearch: (query?: string) => void = useMemo(
		() =>
			debounce((query?: string) => {
				setIsDropdownVisible(!!query);
				// if query has changed, get groups
				if (query !== previousQuery.current && query !== undefined) {
					fetchGroups(query);
				}
				previousQuery.current = query || '';
			}, searchDebounceTimeout),
		[fetchGroups, previousQuery, searchDebounceTimeout],
	);

	const handleOnFocus = useCallback(
		(sessionId?: string): void => {
			setIsDropdownVisible(true);
			onFocus?.(sessionId);
			if (!groups || !groups.length) {
				fetchGroups();
			}
		},
		[fetchGroups, groups, onFocus],
	);

	const handleOnBlur = useCallback(
		(sessionId?: string): void => {
			setIsDropdownVisible(false);

			onBlur?.(sessionId);
			previousQuery.current = '';
		},
		[onBlur, previousQuery],
	);

	const handleOnChange: OnChange = (updatedValue): void => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		onChange(updatedValue as UserPickerOption);
	};

	const getOptions = (): UserPickerOption[] =>
		groups
			.filter((group) => group.name.includes(previousQuery.current))
			.map((group) => transformGroupToUserOption(group));

	const onClear = (): void => {
		onChange(null);
	};

	const closeMenuOnScroll = (e: Event): void => {
		if (isDropdownVisible && onCloseMenuOnScroll && onCloseMenuOnScroll(e)) {
			setIsDropdownVisible(false);
		}
	};

	const getIsClearable = (): boolean => (isClearable ? Boolean(value) : false);

	return (
		<UserPicker
			ariaLabelledBy={fieldProps['aria-labelledby']}
			fieldId={fieldId}
			width={width}
			autoFocus={autoFocus}
			isLoading={loading}
			options={getOptions()}
			value={value}
			onFocus={handleOnFocus}
			onClear={onClear}
			isMulti={isMulti}
			isDisabled={isDisabled}
			isClearable={getIsClearable()}
			onBlur={handleOnBlur}
			onInputChange={handleSearch}
			onChange={handleOnChange}
			placeholder={placeholder}
			addMoreMessage={formatMessage(messages.addMoreGroupsPrompt)}
			UNSAFE_hasDraggableParentComponent
			closeMenuOnScroll={closeMenuOnScroll}
			open={isDropdownVisible}
			menuPosition={menuPosition}
			styles={{
				control: getUserPickerControlStyle,
				...groupPickerStyles,
			}}
		/>
	);
};

export default GroupPicker;
