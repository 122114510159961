import React, { useEffect, useState } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '@atlassian/jira-issue-create-commons/src/common/utils/select/index.tsx';
import type { AffectedServiceOption } from '@atlassian/jira-issue-field-affected-services/src/common/types.tsx';
import AffectedServicesEdit from '@atlassian/jira-issue-field-affected-services/src/ui/edit/index.tsx';
import { StyledFieldSkeleton } from '../../common/ui/styled-common/styled';
import type { Props, AffectedServicesFieldConfig, FieldValue } from './types';
import { transformDefaultAffectedServices, useFetchAffectedServicesName } from './utils';

const AffectedServicesField = (props: Props) => {
	const {
		width,
		fieldProps: { value, onChange, ...fieldProps },
		fieldId,
		error,
		autoCompleteUrl = '',
		isEditable = true,
		nonEditableReason,
		// @ts-expect-error - TS2339 - Property 'onCloseMenuOnScroll' does not exist on type 'Props'.
		onCloseMenuOnScroll = closeMenuOnScroll,
		// @ts-expect-error - TS2339 - Property 'isDropdownMenuFixedAndLayered' does not exist on type 'Props'.
		isDropdownMenuFixedAndLayered = false,
		defaultValue,
	} = props;

	const [shouldFetchServiceName, setShouldFetchServiceName] = useState(true);
	const [selectedOptions, setSelectedOptions] = useState<AffectedServiceOption[]>([]);
	const [{ isLoading }, fetchAffectedServicesName] = useFetchAffectedServicesName();

	useEffect(() => {
		if (shouldFetchServiceName && defaultValue && defaultValue.length > 0) {
			/*
            const ids = defaultValue.map((service) => service.serviceId);
            Simply extracting serviceId above causes Flow error as 'service'
            is of type (JiraAffectedService | AffectedServiceOption).
            See TURTLE-89 for more details.
             */
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const ids = defaultValue.reduce<Array<any>>(
				(serviceIds, service) =>
					// @ts-expect-error - TS2339 - Property 'serviceId' does not exist on type 'AffectedServiceOption'. | TS2339 - Property 'serviceId' does not exist on type 'AffectedServiceOption'.
					service.serviceId != null ? serviceIds.concat(service.serviceId) : serviceIds,
				[],
			);
			if (ids.length > 0) {
				fetchAffectedServicesName(ids, setSelectedOptions);
			}
			setShouldFetchServiceName(false);
		} else {
			setSelectedOptions(value);
		}
	}, [defaultValue, fetchAffectedServicesName, setSelectedOptions, shouldFetchServiceName, value]);

	const getAffectedServicesEdit = () => (
		<AffectedServicesEdit
			{...fieldProps}
			autoCompleteUrl={autoCompleteUrl}
			onChange={onChange}
			value={selectedOptions}
			fieldId={fieldId}
			isInvalid={Boolean(error)}
			isDisabled={!isEditable}
			isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
			onCloseMenuOnScroll={onCloseMenuOnScroll}
			fetchSuggestionsOnFocus
		/>
	);

	if (isLoading) {
		return <StyledFieldSkeleton />;
	}

	return (
		<FieldContainer width={width}>
			{isEditable === false ? (
				<Tooltip content={nonEditableReason?.message || ''}>{getAffectedServicesEdit()}</Tooltip>
			) : (
				getAffectedServicesEdit()
			)}
		</FieldContainer>
	);
};

export default withFormField({
	transformDefaultValue: transformDefaultAffectedServices,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<AffectedServicesFieldConfig, FieldValue>, any>'.
})<AffectedServicesFieldConfig, FieldValue>(AffectedServicesField);
export { mutateAffectedServicesField } from './utils';
