import React, { useCallback, useRef, useState } from 'react';
import { AsyncSelect } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import debouncePromise from '@atlassian/jira-platform-debounce-promise/src/ui/debounced-promise/index.tsx';
import type { GroupOption, CustomTeamFieldProps } from '../../common/types';
import messages from '../../messages';
import MenuListComponent from './menu-list';
import OptionComponent from './option';
import { fetchTeams } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MenuList = (props: any) => <MenuListComponent {...props} />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = (props: any) => <OptionComponent {...props} />;

const CustomTeamPickerEdit = (props: CustomTeamFieldProps) => {
	const {
		fieldId,
		value,
		onChange,
		autoCompleteUrl,
		isInvalid,
		autoFocus = false,
		isDisabled,
		placeholder = '',
		isDropdownMenuFixedAndLayered,
		onCloseMenuOnScroll,
		fetchSuggestionsOnFocus = false,
		classNamePrefix,
		ariaLabel,
		ariaLabelledBy,
	} = props;
	const { formatMessage } = useIntl();

	const [defaultOptions, setDefaultOptions] = useState<GroupOption[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const shouldFetchDefaultOptions = useRef<boolean>(true);

	const handleSearch = debouncePromise(
		async (query?: string): Promise<GroupOption[]> => fetchTeams(query || '', autoCompleteUrl),
		300,
	);
	const onFocus = async () => {
		if (
			shouldFetchDefaultOptions &&
			defaultOptions &&
			shouldFetchDefaultOptions.current &&
			!defaultOptions.length
		) {
			shouldFetchDefaultOptions.current = false;
			setIsLoading && setIsLoading(true);
			const options = await handleSearch();
			setDefaultOptions && setDefaultOptions(options);
			setIsLoading && setIsLoading(false);
		}
	};

	const defaultOptionsProps = () => {
		if (fetchSuggestionsOnFocus) {
			return { onFocus, isLoading, defaultOptions };
		}
		return {};
	};

	const noOptionsMessage = useCallback(() => formatMessage(messages.noMatches), [formatMessage]);

	return (
		<AsyncSelect
			fieldId={fieldId}
			{...defaultOptionsProps()}
			loadOptions={handleSearch}
			onChange={onChange}
			autoFocus={autoFocus}
			isClearable={Boolean(value)}
			isMulti={false}
			value={value}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			components={{
				MenuList,
				Option,
			}}
			noOptionsMessage={noOptionsMessage}
			placeholder={placeholder}
			isDisabled={isDisabled}
			// @ts-expect-error - TS2322 - Type '((e: ChangeEvent<HTMLElement>) => boolean) | undefined' is not assignable to type 'boolean | EventListener | undefined'.
			closeMenuOnScroll={onCloseMenuOnScroll}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			styles={defaultSelectStyles}
			classNamePrefix={classNamePrefix}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledBy}
		/>
	);
};

export default CustomTeamPickerEdit;
