import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { components } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import Team from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/team';
import type { TeamFieldType } from '../../../common/types';

type Props = {
	data: TeamFieldType;
	children: ReactNode;
};

const OptionComponent = (props: Props) => {
	const value = props.data;
	if (value) {
		const { avatarUrl, label } = value;

		return (
			// @ts-expect-error - TS2740 - Type '{ children: Element; data: TeamFieldType; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
			<components.Option {...props}>
				<OptionWrapper>
					{avatarUrl ? (
						<Icon src={avatarUrl} alt={label} />
					) : (
						<Box xcss={boxStyle}>
							<Team alt="" />
						</Box>
					)}

					<OptionText>{props.children}</OptionText>
				</OptionWrapper>
			</components.Option>
		);
	}
	return null;
};

export default OptionComponent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
	alignItems: 'center',
	justifyContent: 'start',
	padding: `0 ${token('space.050', '4px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionText = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const boxStyle = xcss({
	height: '16px',
	width: '16px',
	marginRight: 'space.100',
});
