import { useCallback, useEffect, useState } from 'react';
import componentsCache from '@atlassian/jira-cache/src/services/components/index.tsx';
import {
	type GlobalComponentsProperties,
	getGlobalComponentsProperty,
	setGlobalComponentsProperty,
} from './utils';

export function useGlobalComponentsProperty(projectKey: string) {
	const [loading, setLoading] = useState<boolean>(true);
	const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
	const [error, setError] = useState<Error | undefined>(undefined);

	const loadGlobalComponentsProperty = useCallback(() => {
		setEnabled(undefined);
		setError(undefined);
		setLoading(true);
	}, [setEnabled, setError, setLoading]);

	const resolveGlobalComponentsProperty = useCallback(
		(value: boolean | Error) => {
			if (value instanceof Error) {
				setEnabled(undefined);
				setError(value);
			} else {
				setEnabled(value);
				setError(undefined);
			}

			setLoading(false);
		},
		[setEnabled, setError, setLoading],
	);

	useEffect(() => {
		loadGlobalComponentsProperty();

		getGlobalComponentsProperty(projectKey)
			.then((r: GlobalComponentsProperties) => resolveGlobalComponentsProperty(r.value))
			.catch((e) => resolveGlobalComponentsProperty(e));
	}, [loadGlobalComponentsProperty, resolveGlobalComponentsProperty, projectKey]);

	const setProperty = useCallback(
		(newValue: boolean) => {
			loadGlobalComponentsProperty();

			return (
				setGlobalComponentsProperty(projectKey, newValue)
					// We are clearing the Jira components cache here which is the
					// data source for "Recent components" in the components field
					// for Jira issues. This should be cleared when toggling between
					// global components enabled or disabled to avoid stales
					// suggestions from either.
					.then(() => componentsCache.clearAll())
					.then(() => resolveGlobalComponentsProperty(newValue))
					.catch((e) => {
						resolveGlobalComponentsProperty(e);
						throw e;
					})
			);
		},
		[loadGlobalComponentsProperty, resolveGlobalComponentsProperty, projectKey],
	);

	return {
		enabled,
		loading,
		error,
		setProperty,
	};
}
