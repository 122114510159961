import React from 'react';
import { RadioSelect } from '@atlaskit/select';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { Props } from './types';

const RadioSelectEdit = (props: Props) => {
	const {
		value,
		options,
		onChange,
		onFocus,
		onBlur,
		placeholder = '',
		isDisabled = false,
		autoFocus,
		isInvalid,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		classNamePrefix,
		openMenuOnFocus,
		isClearable = true,
		ariaLabel,
		ariaLabelledBy,
	} = props;
	return (
		<RadioSelect
			classNamePrefix={classNamePrefix}
			value={value}
			options={options}
			isDisabled={isDisabled}
			placeholder={placeholder}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '(arg1: Option) => void' is not assignable to type '(value: OptionType | null, action: ActionMeta<OptionType>) => void'.
			onChange={onChange}
			onFocus={onFocus}
			closeMenuOnScroll={onCloseMenuOnScroll}
			onBlur={onBlur}
			autoFocus={autoFocus}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			isClearable={isClearable}
			openMenuOnFocus={openMenuOnFocus}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledBy}
		/>
	);
};

export default RadioSelectEdit;
