import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue-field-sprint.edit.placeholder',
		defaultMessage: 'Select sprint',
		description: 'Placeholder text for select component',
	},
	empty: {
		id: 'issue-field-sprint.edit.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-sprint.edit.failed-fetch',
		defaultMessage: 'Search failed. Please try again.',
		description: 'Message when fetching suggestions fails',
	},
	loading: {
		id: 'issue-field-sprint.edit.loading',
		defaultMessage: 'Searching...',
		description: 'Message showed when options are being loaded from the server.',
	},
});
