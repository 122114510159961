import React from 'react';
import AssigneeField from '@atlassian/jira-issue-create-commons/src/ui/assignee-field/index.tsx';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config';
import { AssigneeFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { assigneeFieldWriter } from '../writers';

const AssigneeFieldTypeComponent = (props: ProvidedFieldProps) => <AssigneeField {...props} />;

export const AssigneeFieldTypeConfig: FieldTypeConfig<UserValue> = {
	type: ASSIGNEE_TYPE,
	Component: AssigneeFieldTypeComponent,
	placeholder: <AssigneeFieldPlaceholder />,
	writer: assigneeFieldWriter,
};
