import React from 'react';
import TextField from '@atlassian/jira-issue-create-commons/src/ui/text-field/index.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config';
import { TextFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig } from '../types';
import { textFieldWriter } from '../writers';

export const SummaryFieldTypeConfig: FieldTypeConfig<string> = {
	type: SUMMARY_TYPE,
	Component: TextField,
	placeholder: <TextFieldPlaceholder />,
	writer: textFieldWriter,
};
