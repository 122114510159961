import flatMap from 'lodash/flatMap';
import { memoizedHashString } from '@atlassian/jira-issue-analytics/src/utils/hash-attribute/index.tsx';
import type { LabelOption as Option } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import type { Group } from './types';

export const getSafeValue = (val: string | number): string =>
	memoizedHashString(val.toString()).toString();

export const getSafeOptionsForAnalytics = (items: ReadonlyArray<Readonly<Option>>): string[] =>
	items.filter((item) => item.value).map(({ value }) => getSafeValue(value));

export const flattenOptions = (optionGroups: Group[]): Option[] =>
	flatMap(optionGroups.map((group) => group.options));

export const getOptionValuesLengths = (items: ReadonlyArray<Readonly<Option>>): number[] =>
	items.filter((item) => item.value).map(({ value }) => (value != null ? `${value}`.length : 0));

export const filterOptions = (options: Option[], query?: string): Option[] =>
	options.filter(
		(option) =>
			(typeof option.value === 'string' &&
				option.value.toLowerCase().includes((query || '').toLowerCase())) ||
			(typeof option.label === 'string' &&
				option.label.toLowerCase().includes((query || '').toLowerCase())),
	);
