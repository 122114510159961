import React from 'react';
import NumberField from '@atlassian/jira-issue-create-commons/src/ui/number-field/index.tsx';
import { NUMBER_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { TextFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { numberFieldWriter } from '../writers';

const NumberCustomFieldTypeComponent = (props: ProvidedFieldProps) => <NumberField {...props} />;

export const NumberCustomFieldTypeConfig: FieldTypeConfig<string> = {
	type: NUMBER_CF_TYPE,
	Component: NumberCustomFieldTypeComponent,
	placeholder: <TextFieldPlaceholder />,
	writer: numberFieldWriter,
};
